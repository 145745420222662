import React from "react";
import SEO from "../../components/seo";
import useProfilePic from "../../hooks/useProfilePic";
import ProfilePage from "../../layouts/ProfilePage";

const Duy = () => {
	const { duy: pic } = useProfilePic();
	return (
		<>
			<SEO
				title="Our Team of Specialists"
				keywords={[`Gastroenterologist`, `Dr Duy Pham`]}
				description="About Dr Duy Pham"
			/>
			<ProfilePage
				title="Duy Pham - Gastroenterologist & Hepatologist"
				name="Dr Duy Pham"
				img={pic.childImageSharp.fixed}
				quote=""
				specialty="All aspects of liver disease"
				education={[
					"Undergraduate: University of Western Australia",
					"Postgraduate GI Training: Sir Charles Gairdner Hospital, Perth, Western Australia",
					"Postgraduate GI Training: Royal Perth Hospital, Perth, Western Australia",
					"Fellowship: Austin Health, Victoria",
				]}
				description={[
					"Duy’s clinical interests include all aspects of liver disease including viral hepatitis B and C, fatty liver disease, alcohol-related liver disease, autoimmune and metabolic conditions, liver lesions and cirrhosis.",
					"Duy is English and Vietnamese speaking.",
				]}
				honours={[
					"",
				]}
				background="Dr Pham graduated with a Bachelor of Medicine and Surgery from the University of Western Australia and is a Fellow of the Royal Australasian College of Physicians. After completing his specialist training in gastroenterology and hepatology in WA, he undertook a clinical hepatology fellowship at the Liver Transplant Unit at Austin Health in Victoria."
				appointments={[
					"Private appointment - Hollywood Private Hospital, Perth",
					"Public locum appointment - Sir Charles Gairdner Hospital, Perth",
					"Public appointment - Osborne Park Hospital (ASI Open Access Endoscopy)",
				]}
				memberships={[
					"Australian Health Practitioner Regulation Agency (AHPRA)",
					"The Royal Australasian College of Physicians (RACP)",
				]}
				personal={[
					"",
				]}
				publications={[

				]}
			/>
		</>
	);
};

export default Duy;
